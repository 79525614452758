import React, { useEffect } from "react";
import { ThemeProvider } from 'styled-components';
import { Routes, Route } from 'react-router-dom';
import configuration from "./configuration";
import Home from "./modules/Home";
import Results from "./modules/Content/ResultScreen";
import InformationScreen from "./modules/Content/InformationScreen";
// import SiteUnderConstruction from "./modules/Content/SiteUnderConstruction";

const App = (props) => {
   const { theme } = configuration;

   //TODO: For clarity Tag in Head section
   useEffect(() => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.innerHTML = `
        (function(c,l,a,r,i,t,y) {
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "ogcvaqudl5");
      `;
      
      document.head.appendChild(script);
  
      // Cleanup the script when component unmounts (optional)
      return () => {
        document.head.removeChild(script);
      };
    }, []);

   return (
      <>
         <ThemeProvider theme={theme}>
            <Routes>
               <Route exact path="/" element={<Home />} />
               {/* <Route exact path="/" element={<SiteUnderConstruction />} /> */}
               <Route exact path="/info" element={<InformationScreen />} />
               <Route exact path="/results" element={<Results />} />
            </Routes>
         </ThemeProvider>
      </>
   );
};

export default App;
